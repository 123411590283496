import apiClient, { postParams } from 'lib/api/apiClient';

/**
 * 나중에보기
 * @param action 추가 | 삭제
 * @param titleNo 영상번호
 * @param bjId? BJ ID(삭제일 때만 필요)
 * @returns
 */

export type TLaterType = 'add' | 'delete';

export const setLaterView = async (
	action: TLaterType,
	broadNo: string | number,
) => {
	let params = {};

	params = {
		broad_no: broadNo,
		type: 'LIVE',
	};

	const { data } = await apiClient.post(`${API_M_AFREECATV}/station/video/later/a/${action}`, postParams(params));

	return data;
};
