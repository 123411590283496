import React, { useState } from 'react'
import { ILarvaRankData } from 'types/larva';

export const ShareContext = React.createContext<IShareContext>({} as IShareContext);


export interface IShareContext{
    shareBroad : ILarvaRankData
    shareLoad : boolean
    shareTop : number 
    shareLeft : number
    setShareBroad: React.Dispatch<React.SetStateAction<ILarvaRankData>>
    setShareLoad : React.Dispatch<React.SetStateAction<boolean>> 
    setShareTop : React.Dispatch<React.SetStateAction<number>>
    setShareLeft : React.Dispatch<React.SetStateAction<number>>
}

const ShareProvider: React.FC = ({ children }) =>{
    const [shareBroad, setShareBroad] = useState({} as ILarvaRankData)
    const [shareLoad, setShareLoad] = useState(false);
    const [shareTop, setShareTop] = useState(0);
    const [shareLeft, setShareLeft] = useState(0);

    return (
        <ShareContext.Provider value={{shareBroad, shareLoad, shareTop, shareLeft, setShareBroad, setShareLoad, setShareTop, setShareLeft}} >
            {children}
        </ShareContext.Provider>
    )
}
export default ShareProvider;