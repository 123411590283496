import React,{ useEffect, useState } from 'react';
import { AuthProvider } from "react-navigationbar";
import IctProvider from "component/context/IctProvider";
import AfPcStarRankPage from "pages/af/pc/AfPcStarRankPage";
import AfMobileStarRankPage from "pages/af/mobile/AfMobileStarRankPage";
import ThreeDotProvider from 'component/context/ThreeDotProvider';
import ShareProvider from 'component/context/ShareProvider';



function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.userAgentInfo.accessType === 'mobileWeb' ? setIsMobile(true) : setIsMobile(false);
  },[]);
  
  return (
    <AuthProvider>
      <IctProvider>
      <ShareProvider>
      <ThreeDotProvider>
      
       {isMobile ? <AfMobileStarRankPage /> : <AfPcStarRankPage />}
       </ThreeDotProvider>
       
       </ShareProvider>
       </IctProvider>
    </AuthProvider>
  );
}

export default App;
