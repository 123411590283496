import React, { useEffect, useState, useRef } from 'react';
import { checkFavorite, setFavorite } from 'lib/api/favorite';
import { useAuth } from "react-navigationbar";
import Button from 'component/common/Button';
import { useOnClickOutside } from 'usehooks-ts'
import { toast } from 'afreecatv-react-toast';

interface ContextMenu {
	bjId : string
    bjNick : string
    openIct : boolean
    handleOpenIct : (params:boolean) => void
}

const ContextMenu : React.FC<ContextMenu>  = ({bjId,bjNick,openIct,handleOpenIct}) => {
    const { userId } = useAuth();
    const [isFavorite, setIsFavorite] = useState(false);
    
    const iconRef = useRef(null);
    
    
    const handleClickOutside = () => {
        handleOpenIct(false);
    }

    const onClickfavoriteBtn = () => {
        if (userId === '') {
            toast.success('로그인후 시도하세요');
		} else {
			// 로그인
			const action = isFavorite ? 'delete' : 'add';
			const response = setFavorite(bjId, action);
			response
				.then(({ result, message }) => {
					if (result === 1) {
                        toast.success(message);
                        isFavorite ? setIsFavorite(false) : setIsFavorite(true);						
					}
				})
				.catch((e) => {
				});
		}
    }
    const onClickNoteBtn = () => {
        const oWindow = window.open(
            `${NOTE_AFREECATV}/app/index.php?page=write&id_list=${bjId}`,
            "noteWriteWindow",
            "left=10,top=10,width=400,height=440,marginwidth=0,marginheight=0,resizable=0,scrollbars=no",
        );
        if (!oWindow) alert("차단된 팝업을 허용해주세요.");
        else oWindow.focus();
    }

    const onClickStationBtn = () =>{
        window.open(`${BJ_AFREECATV}/${bjId}`);
    }

    const onClickNickSearch = () =>{
        const szBjNick = encodeURIComponent(bjNick);

        window.open(`${WWW_AFREECATV}/total_search.html?szSearchType=total&szStype=ni&szKeyword=${szBjNick}&szLocation=starrank`);
    }
    

    useOnClickOutside(iconRef,handleClickOutside);

    useEffect(() => {
        if(openIct){
            const response = checkFavorite(bjId);
            response
                .then(({ result, data }) => {
                    if (result !== 1) {
                        return;
                    }

                    if (data) {
                        setIsFavorite(true);
                    }else{
                        setIsFavorite(false);
                    }
                })
                .catch((e) => {});
        }
        
    },[openIct,bjId]);

    
    


    return(
    <div className="icon-menu" ref= {iconRef} style={{ display: (openIct ? "block" : "none") }}>
        <Button className="bj_bs" tip="방송국"onClick={onClickStationBtn}><span>방송국 가기</span></Button>
        <Button className="send_memo" tip="쪽지 보내기" onClick={onClickNoteBtn}><span>쪽지 보내기</span></Button>
        <Button className={isFavorite ? "favor on" : "favor" } tip={isFavorite ? "즐겨찾기 제거" : "즐겨찾기 추가"} onClick={onClickfavoriteBtn}><span>즐겨찾기</span></Button>
        <Button className="srh" tip="닉네임 검색" onClick={onClickNickSearch}><span>방송국 게시글/VOD 검색</span></Button>
    </div>
);
}

export default ContextMenu;
