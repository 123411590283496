import React from 'react';

/**
 * button 태그 공용 컴포넌트
 */

interface IButtonProps {
	type?: 'button' | 'submit' | 'reset' | undefined;
	className?: string | undefined;
	title?: string | undefined;
	// eslint-disable-next-line no-unused-vars
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	// eslint-disable-next-line no-unused-vars
	onChange?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onTouchEnd?: React.TouchEventHandler<HTMLButtonElement> | undefined;
	onTouchMove?: React.TouchEventHandler<HTMLButtonElement> | undefined;
	id?: string | undefined;
	tip?: string | undefined;
	disabled?: boolean;
	children?: React.ReactNode;
	style?: React.CSSProperties | undefined;
	ref?: React.LegacyRef<HTMLButtonElement> | undefined;
	dataValue?: string;
	tabIndex?: number | undefined;
	role?: React.AriaRole | undefined;
	ariaLabel?: string | undefined;
	ariaDisabled?: boolean | undefined;
}

const Button: React.FC<IButtonProps> = ({
	type,
	className,
	title,
	onClick,
	onChange,
	onTouchEnd,
	onTouchMove,
	id,
	tip,
	disabled,
	children,
	style,
	ref,
	dataValue,
	tabIndex,
	role,
	ariaLabel,
	ariaDisabled,
}) => (
	<button
		type={type}
		title={title}
		onClick={onClick}
		onChange={onChange}
		onTouchEnd={onTouchEnd}
		onTouchMove={onTouchMove}
		className={className}
		id={id}
		//@ts-ignore
		// button 태그에는 tip속성이라는게 없어서 ts에러 발생.. css에 적용되어 있어서 ignore시킴
		tip={tip}
		disabled={disabled}
		style={style}
		ref={ref}
		data-value={dataValue}
		tabIndex={tabIndex}
		role={role}
		aria-label={ariaLabel}
		aria-disabled={ariaDisabled}
	>
		{children}
	</button>
);
Button.defaultProps = {
	type: 'button',
	className: undefined,
	title: undefined,
	id: undefined,
	tip: undefined,
	disabled: undefined,
	style: undefined,
	ref: undefined,
	tabIndex: undefined,
	role: undefined,
	ariaLabel: undefined,
	ariaDisabled: undefined,
};

export default Button;
