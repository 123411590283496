import React, {useState, useCallback} from 'react';
import {  ILarvaRankData } from 'types/larva';
import Anchor from 'component/common/Anchor';
import Button from 'component/common/Button';
import MobileBroadDetailMenu from 'component/ict/MobileBroadDetailMenu';
import MobileContextMenu from "component/ict/MobileContextMenu";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { profileImage } from 'lib/utils';

interface IMobileBroadItemBox {
	broadItem : ILarvaRankData
    itemIndex : number
    rankingCode : string
}


const MobileBroadItemBox : React.FC<IMobileBroadItemBox> = ({broadItem, itemIndex, rankingCode}) => {

    const [openIct, setOpenIct] = useState(false);
    const [thumbClass, setThumbClass] = useState("");

    const onDetailClick = ()=>{
        setOpenIct(true);
	}

    const handleOpenIct = (flag: boolean) => {
		setOpenIct(flag);
	}

    const onClickLiveBroad = (user_id:string, broad_no:number)=>{
        afreeca.link.live(user_id,broad_no);
    }

    /**
     * 라이브 이미지 에러
     * @param e
     */
    const handleLiveImageError = useCallback((liveThumb) => (e: React.ChangeEvent<HTMLImageElement>) => {
        let liveImage = `${liveThumb}.jpg`;

        let image = new Image();

        image.src = liveImage;

        image.onload = () => {
            e.target.src = liveImage;
        }

        image.onerror = () => {
            setThumbClass("thumb-default");
        }

        return false;
    }, []);

    return(
        <li>
            <Anchor onClick={()=>onClickLiveBroad(broadItem.user_id,broadItem.broad_no)} className={`thumb_box ${thumbClass}`}>
                <LazyLoadImage 
                src={`${broadItem.sn_url}.webp`} 
                onError={handleLiveImageError(broadItem.sn_url)}
                alt=""/>
            </Anchor>
            <em className='rank'>{itemIndex}</em>  
            <div className="info_box">
                <h3><Anchor onClick={()=>onClickLiveBroad(broadItem.user_id,broadItem.broad_no)} className="title">{broadItem.title}</Anchor></h3>
                <MobileBroadDetailMenu rankItem={broadItem}/>
                <Button type="button" className="btn_more" onClick = {onDetailClick}>상세정보</Button>
                {openIct ? <MobileContextMenu bjId={broadItem.user_id} openIct={openIct} handleOpenIct={handleOpenIct}/> : <></>}
            </div>
        </li>
    );
}

export default MobileBroadItemBox;
