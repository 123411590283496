import React, {useCallback} from 'react';
import Anchor from 'component/common/Anchor';
import Button from 'component/common/Button';
import { ILarvaRankData } from 'types/larva';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { profileImage } from 'lib/utils';
interface IMobileBroadDetailMenu {
	rankItem :ILarvaRankData
}


const MobileBroadDetailMenu : React.FC<IMobileBroadDetailMenu> = ({rankItem}) => {
	
	const onNickNameClick = (user_id:string)=>{
		afreeca.link.goStation(user_id);
	}
	const onClickStationLink = (e: React.MouseEvent<HTMLAnchorElement>, user_id:string) =>{
        e.preventDefault();
        afreeca.link.goStation(user_id);
	}
	
	/**
     * 프로필 이미지 에러 
     * @param e 
     */
	const handleImageError = useCallback((userId) => (e: React.ChangeEvent<HTMLImageElement>) => {
		let profileImg = profileImage(userId, true);
		e.target.src = profileImg;
        /**
         * jpg도 에러날시 기본 이미지로
         */

        e.target.onerror = () => {
			profileImg = `${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`
			e.target.src = profileImg;
			return false;
		}

		return false;
	}, []);
    
    return (
		<div className='detail'>
            <Anchor className="thumb" onClick={(e)=>onClickStationLink(e,rankItem.user_id)} target='_blank'>
				<LazyLoadImage 
					src={profileImage(rankItem.user_id)} 
					onError={handleImageError(rankItem.user_id)} 
					alt=""/>
			</Anchor>
            <Button className="nick" onClick={()=>onNickNameClick(rankItem.user_id)}>{rankItem.user_nick}</Button>
            <span className='view'><em>{rankItem.total_view_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</em></span>
		</div>
	);
}

export default MobileBroadDetailMenu;
