import React from 'react';
import { ILarvaRankListData, ILarvaRankData } from 'types/larva';
import MobileBroadItemBox from 'component/af/mobile/MobileBroadItemBox'

interface IMobileBroadItem {
	broadItemList :ILarvaRankListData
    rankingCode : string
}


const MobileBroadItem : React.FC<IMobileBroadItem> = ({broadItemList, rankingCode}) => {

    if(broadItemList.rank_list.length>0){
        return(
            <section className='area_box'>
                <div className='rank-title'>
                <h3>{broadItemList.title}</h3>
                    <span>{broadItemList.detail}</span>
                </div>
                <div className='broad_list_box horizon' data-type="scroll-horizon">
                    <ul className='scroll-innr'>
                    {broadItemList.rank_list.map((broadItem: ILarvaRankData, index: number) => {
                        return(<MobileBroadItemBox broadItem={broadItem} itemIndex ={index+1} rankingCode= {rankingCode}/>);
                    })}
                    </ul>
                </div>
            </section>
        );
    }else{
        return(<></>);
    }
    
}

export default MobileBroadItem;
