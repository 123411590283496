import React from 'react';
import Anchor from 'component/common/Anchor';
import { ILarvaRankData } from 'types/larva';

interface IMobileDetailMenu {
	rankItem :ILarvaRankData
}


const MobileDetailMenu : React.FC<IMobileDetailMenu> = ({rankItem}) => {	

	const onClickNickEvent = (e: React.MouseEvent<HTMLAnchorElement>, user_id:string)=>{
		e.preventDefault();
		afreeca.link.goStation(user_id);
	}
    
    return (
		<div className='details'>
			<Anchor className="nick" target="_blank" onClick={(e)=>onClickNickEvent(e,rankItem.user_id)}><span>{rankItem.user_nick}</span></Anchor>
			<span className='views'><em>{rankItem.total_view_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}명 참여중</em></span>
		</div>
	);
}

export default MobileDetailMenu;
