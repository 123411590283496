import React from 'react';

/**
 * a 태그 공용 컴포넌트
 */

interface IAnchorProps {
	href?: string;
	target?: string;
	title?: string;
	className?: string;
	order?: string;
	id?: string;
	tagRef?: React.RefObject<HTMLAnchorElement> | null;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	onTouchEnd?: (e: React.TouchEvent<HTMLAnchorElement>) => void;
	children?: any;
	style?: React.CSSProperties | undefined;
	dataName?: string;
	dangerouslySetInnerHTML?: any;
	tip?: string;
	rel?: string | undefined;
}

const Anchor: React.FC<IAnchorProps> = ({
	href,
	target,
	title,
	id,
	tagRef,
	className,
	order,
	onClick,
	onTouchEnd,
	children,
	style,
	dataName,
	dangerouslySetInnerHTML,
	tip,
	rel,
}) => {
	return href !== '' ? (
		<a
			href={href}
			target={target}
			title={title}
			// rel="noopener noreferrer"
			id={id}
			onClick={onClick}
			ref={tagRef}
			className={className}
			style={style}
			data-name={dataName}
			dangerouslySetInnerHTML={dangerouslySetInnerHTML}
			tip={tip}
			rel={rel}
		>
			{children}
		</a>
	) : (
		// eslint-disable-next-line
		<a
			href="#"
			onClick={onClick}
			onTouchEnd={onTouchEnd}
			title={title}
			id={id}
			ref={tagRef}
			className={className}
			style={style}
			data-name={dataName}
			dangerouslySetInnerHTML={dangerouslySetInnerHTML}
			tip={tip}
		>
			{children}
		</a>
	);
};

Anchor.defaultProps = {
	href: '',
	target: '',
	title: undefined,
	className: undefined,
	id: undefined,
	tagRef: null,
	dataName: undefined,
	dangerouslySetInnerHTML: undefined,
	tip: undefined,
	rel: undefined,
};

export default Anchor;
