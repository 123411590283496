import React, {useEffect, useState, useCallback, useRef, SyntheticEvent, useContext} from 'react';
import { ILarvaRankData} from 'types/larva';
import { setLaterView } from 'lib/api/laterView';
import Anchor from 'component/common/Anchor';
import BroadDetailMenu from 'component/ict/BroadDetailMenu';
import { toast } from 'afreecatv-react-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { profileImage } from 'lib/utils';
import Button from 'component/common/Button';
import { ThreeDotContext } from 'component/context/ThreeDotProvider';
import useAutoHashTag from 'lib/hooks/useAutoHashTag';

interface BroadItem {
	broadItem :ILarvaRankData
    itemIndex : number
    autoTagStyle: any
}

const BroadItemBox : React.FC<BroadItem> = ({broadItem, itemIndex, autoTagStyle}) => {
    const {setDotBroad, setDotLoad, setDotTop, setDotLeft} = useContext(ThreeDotContext);
    const [thumbClass, setThumbClass] = useState("");
    const {getTagStyle} = useAutoHashTag();

    const onClickLaterViewBtn = (broad_no : number) =>{
        const response = setLaterView('add', broad_no);
        
		response
			.then(({ result, message, data }) => {
				switch (result) {
					case 1:
                        toast.success(message);
						return;
					default:
						if (data.message) {
							toast.success(data.message);
						}
						return;
				}
			})
			.catch(() => {
				toast.success('서비스를 이용하실 수 없습니다. 잠시 후 다시 이용해 주십시오.');
			});
    }

    useEffect(() => {
        if(broadItem.broad_pwd !== "" && broadItem.broad_grade === 19){
            setThumbClass('thumb-lock_adult');
        }else if(broadItem.broad_pwd !== ""){
            setThumbClass('thumb-lock');
        }else if(broadItem.broad_grade === 19){
            setThumbClass('thumb-adult');
        }
        
    },[broadItem.broad_pwd,broadItem.broad_grade]);

    /**
     * 프로필 이미지 에러 
     * @param e 
     */
    const handleImageError = useCallback((userId) => (e: React.ChangeEvent<HTMLImageElement>) => {
		let profileImg = profileImage(userId, true);
        e.target.src = profileImg;
        /**
         * jpg도 에러날시 기본 이미지로
         */
        e.target.onerror = () => {
			profileImg = `${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`
			e.target.src = profileImg;
			return false;
		}
		return false;
    }, []);

    /**
     * 라이브 이미지 에러
     * @param e
     */
    const handleLiveImageError = useCallback((e: React.ChangeEvent<HTMLImageElement>) => {
        let liveImage = `${broadItem.sn_url}.jpg`;

        e.target.src = liveImage;

        e.target.onerror = () => {
            setThumbClass("thumb-default");
            return false;
        }
        return false;
    }, []);

    const openThreeDot = useCallback((e:SyntheticEvent<HTMLButtonElement,Event>, broad : ILarvaRankData)=>{
        setDotLoad(true);
        setDotBroad(broad);
        setDotTop(window.scrollY +e.currentTarget.getBoundingClientRect().top + e.currentTarget.clientHeight);
        setDotLeft(window.scrollX +e.currentTarget.getBoundingClientRect().left +e.currentTarget.clientWidth);
    },[]);

    const handleChangeStyle = useCallback((hoverCheck, style, tagEvent) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (hoverCheck && tagEvent !== null) {
            e.currentTarget.style.background = tagEvent.hover.background;
        } else {
            e.currentTarget.style.background = style.background;
        }
    }, []);

    return(
        <li data-type="cBox">
            <div className="thumbs-box">
                <Anchor href={broadItem.url} target="_blank" className={thumbClass}>
                    {thumbClass === "" ? <LazyLoadImage src={`${broadItem.sn_url}.webp`} alt=""  onError={handleLiveImageError}></LazyLoadImage> : <></> }
                </Anchor>
                <span className="rank">{itemIndex}</span>
                {broadItem.broad_type === 40 ? <span className="ppv">유료</span> : <></>}
                {broadItem.visit_broad_type === 1 ? <span className="allow">탐방허용</span> : <></>}
                <span className="time">{broadItem.broad_start.substring(5)} 방송시작</span>
                <button type="button" className="btn-later" onClick={()=>onClickLaterViewBtn(broadItem.broad_no)} tip="나중에 보기"><span>나중에 보기</span></button>
            </div>
            <div className="cBox-info">
                <Anchor 
                    href={`${BJ_AFREECATV}/${broadItem.user_id}`} 
                    className="thumb" 
                    target="_blank">
                        <LazyLoadImage 
                            src={profileImage(broadItem.user_id)}  
                            onError={handleImageError(broadItem.user_id)}
                            alt=""/>
                </Anchor>
                <BroadDetailMenu rankItem={broadItem}/>
                <h3>
                    <Anchor href={broadItem.url} className="title"  target="_blank">{broadItem.title}</Anchor>
                    <Button type="button" className="more_dot" onClick={(e)=>{openThreeDot(e, broadItem)}}><span>더보기 메뉴</span></Button>    
                    
                </h3>
                <div className="tag_wrap">
                    {
                        broadItem.auto_hashtags && broadItem.auto_hashtags.filter(item=> item !== null && item!=='').map((tag) => {
                            let tagStyle = getTagStyle(tag, autoTagStyle);

                            return(<a href={"https://www.afreecatv.com/?hash=hashtag&tagname="+tag+"&location=etc&stype=hash&hashtype=live"} style={tagStyle.style} 
                                onMouseOver={handleChangeStyle(true, tagStyle.style, tagStyle.events)} 
                                onMouseOut={handleChangeStyle(false, tagStyle.style, tagStyle.events)}>#{tag}</a>)
                        })
                    }
                    {
                        broadItem.category_tags.filter(item=> item !== null && item!=='').map((tag)=>{
                                return(<a href={"https://www.afreecatv.com/?hash=hashtag&tagname="+tag+"&location=etc&stype=hash&hashtype=live"}>#{tag}</a>)
                        })
                                
                    }  
                    {
                        broadItem.hash_tags.filter(item=> item !== null && item!=='').map((tag)=>{
                                return(<a href={"https://www.afreecatv.com/?hash=hashtag&tagname="+tag+"&location=etc&stype=hash&hashtype=live"}>#{tag}</a>)
                        })
                                
                    }   
                </div>
            </div>
        </li>
    );
}

export default BroadItemBox;
