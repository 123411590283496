import { useEffect, useState, useMemo } from 'react';
import { getEventTagStyle } from 'lib/api/larva';
import { getCookie } from 'lib/cookie';

interface AutoHashTag {
    tag_name: string,
    light?: any,
    dark?: any
}

interface AutoHashTagStyle {
    style: any,
    events: any
}

const useAutoHashTag = () => {
    const [dark, setDark] = useState<boolean>(false);
    const [autoHashTagStyle, setAutoHashTagStyle] = useState<any>([]);

    const initHashStyle = () => {
        if(autoHashTagStyle.length < 1) {
            const tagStyle = getEventTagStyle();

            tagStyle.then((response) => {
                setAutoHashTagStyle(response);
            })
            .catch(error => {
                console.log(error);
            });
            
        }
    }
    useEffect(() => {
        // 초기 셋팅
        setDark(getCookie('theme') === 'dark');

    }, [setDark, getCookie]);

    useEffect(() => {
        // darkmode check box change
        let darkModeCheck = document.querySelector("#modecheck");
        const onDark = () => {
            setDark(getCookie('theme') === 'dark');
        }

        darkModeCheck?.addEventListener('change', onDark);

        return () => darkModeCheck?.removeEventListener('change', onDark);
    }, []);

    const getTagStyle = (key: string, autoTagStyles: any) => {
        let styles: AutoHashTagStyle = {style: null, events: null};

        if(autoTagStyles && autoTagStyles.length > 0) {
            autoTagStyles.find((style: AutoHashTag) => {
                if(style.tag_name === key) {
                    if(dark) {
                        styles = {
                            'style': style.dark.pc.style,
                            'events': style.dark.pc.event
                        };
                    } else {
                        styles = {
                            'style': style.light.pc.style,
                            'events': style.light.pc.event
                        };
                    }
                }
            })
        }
        return styles;
    }

    return useMemo(() => ({
        autoHashTagStyle,
        dark,
        initHashStyle,
        getTagStyle
    }), [autoHashTagStyle, dark, initHashStyle, getTagStyle]);
}

export default useAutoHashTag;