import axios from 'axios';
import qs from 'qs';

export const postParams = (params: any) => qs.stringify(params);

const apiClient = axios.create();
apiClient.defaults.withCredentials = true;
apiClient.defaults.xsrfCookieName = undefined; // xsrf사용안함
apiClient.defaults.xsrfHeaderName = undefined; // xsrf사용안함
apiClient.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default apiClient;
