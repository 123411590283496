import React from 'react';
import Anchor from "component/common/Anchor";
import Button from "component/common/Button";
import { ShareContext } from "component/context/ShareProvider";
import { ThreeDotContext } from "component/context/ThreeDotProvider";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "usehooks-ts";
import { toast } from 'afreecatv-react-toast';
/**
 * 더보기 > 공유하기 컴포넌트
 */

const ShareContextMenu = () =>{
    const {t} = useTranslation();
    const {shareBroad, shareLoad, shareTop, shareLeft, setShareLoad} = useContext(ShareContext);
    const shareRef = useRef<HTMLDivElement>(null);
    const shareUrl = PLAY_AFREECATV+"/"+shareBroad.user_id+"/" + shareBroad.broad_no;
    const shareText = shareBroad.broad_title;

    const LNBDiv = document.getElementById("serviceLnb") as HTMLDivElement;
    const LNBWidth = LNBDiv !== null ? LNBDiv.clientWidth : 0;

    const handleClickOutside = (e :MouseEvent) => {
      e.stopPropagation();
    	setShareLoad(false);
    }

    useOnClickOutside(shareRef,handleClickOutside);
   
   


    const shareTwitter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        

        // sendVodUaLog('twitter_btn');
        window.open('https://twitter.com/intent/tweet?url='+shareUrl+'&text='+shareText);
        setShareLoad(false);
    }

    const shareFacebook = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        // sendVodUaLog('facebook_btn');
        window.open('https://www.facebook.com/sharer/sharer.php?u='+shareUrl);
        setShareLoad(false);
    }

    const copyShareUrl = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
         try{
            // sendVodUaLog('copy_btn');
            let urlbox = document.createElement('input');
            urlbox.value = shareUrl
            urlbox.style.cssText="position:absolute;top:0;left:0;width:1px;height:1px;margin:0;padding:0;border:0;";
            document.body.append(urlbox);
            
            urlbox.select();
            document.execCommand( 'copy' );
    
            toast.success(t('주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요.'));
            setShareLoad(false);
            
        }catch (err) {
            alert(t('브라우저에서 지원 하지 않는 기능 입니다.'));
        }
    }

    const closeShare = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>)  =>  {
      setShareLoad(false);
    }
    if(shareLoad === true ){
        return(
            <>
                <div ref ={shareRef} className="ui-pop bs-sns_layer" style ={shareLoad ? {position:"absolute", top : shareTop, left: shareLeft-LNBWidth,visibility:"visible", opacity :1}:{}}> 
                    <div className="pop-title">
                        <h3>{t("공유하기")}</h3>
                    </div> 
                    <div className="pop-body">
                        <div> 
                            <Button type="button" className="tw" onClick = {(e)=>{shareTwitter(e)}}><em></em></Button> 
                            <Button type="button" className="fb" onClick = {(e)=>{shareFacebook(e)}}><em></em></Button>
                            <Button type="button" className="url" onClick ={(e)=>{copyShareUrl(e)}}><em></em></Button> 
                        </div> 
                    </div> 
                    <Anchor className="pop-close" onClick= {(e)=>{closeShare(e)}}><span>공유하기 레이어 닫기</span></Anchor> 
                </div>
            </>
        )
    }else{
        return(<></>)
    }
}

export default ShareContextMenu;