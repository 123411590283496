import React, { useEffect, useState, useCallback } from 'react';
import { getLarvaList } from 'lib/api/larva';
import { setLaterView } from 'lib/api/laterView';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Footer, GNB, LNB, LnbMiniProvider } from "react-navigationbar";
import ReactToast from "afreecatv-react-toast";
import { Swiper,SwiperSlide } from 'swiper/react/swiper-react.js';
import Anchor from 'component/common/Anchor';
import Button from 'component/common/Button';
import { ILarvaRankListData, ILarvaSendData, ILarvaRankData } from 'types/larva';
import DetailMenu from 'component/ict/DetailMenu';
import BroadItem from 'component/af/pc/BroadItem';
import { toast } from 'afreecatv-react-toast';
import ThreeDotContextMenu from 'component/threedot/ThreeDotContextMenu';
import ShareContextMenu from 'component/threedot/ShareContextMenu';
import useAutoHashTag from 'lib/hooks/useAutoHashTag';


const AfPcStarRankPage: React.FC = () => {
    const [larvaBestData, setLarvaBestData] = useState<ILarvaRankData[]>([]);
    const [larvaBestDataCnt, setLarvaBestDataCnt] = useState(0);
    const [larvaListData, setLarvaListData] = useState<ILarvaRankListData[]>([]);
    const [thumbClass, setThumbClass] = useState("");
    const { autoHashTagStyle, initHashStyle } = useAutoHashTag();


    const oLarvaSendData:ILarvaSendData = {
        szWork : "getStarRank",
        nCount : 4
    };

    useEffect(() => {
        const oLarvaData = getLarvaList(oLarvaSendData);
        initHashStyle();// style 최초 로드
        
        oLarvaData.then((result) => {
            setLarvaBestData(result.DATA.bestData);
            setLarvaListData(result.DATA.themeData);
            setLarvaBestDataCnt(result.DATA.bestDataCnt);
        });

        document.body.setAttribute('id','listMain');
        document.body.classList.add('broadRanking_wrap');
   },[]);

   const onClickLaterViewBtn = (broad_no : number) =>{

        const response = setLaterView('add', broad_no);
        
		response
			.then(({ result, message, data }) => {
				switch (result) {
					case 1:
                        toast.success(message);
						return;
					default:
						if (data.message) {
							toast.success(data.message);
						}
						return;
				}
			})
			.catch(() => {
				toast.success('서비스를 이용하실 수 없습니다. 잠시 후 다시 이용해 주십시오.');
			});
   }

    /**
     * 라이브 이미지 에러
     * @param e
     */
    const handleLiveImageError = useCallback((liveThumb) => (e: React.ChangeEvent<HTMLImageElement>) => {
        let liveImage = `${liveThumb}.jpg`;

        let image = new Image();

        image.src = liveImage;

        image.onload = () => {
            e.target.src = liveImage;
        }

        image.onerror = () => {
            setThumbClass("thumb-default");
        }

        return false;
    }, []);
    
   if(larvaBestDataCnt>0){
    return (
        <div>
        <div id="wrap">
            <LnbMiniProvider>
                <GNB />
                <LNB />
            </LnbMiniProvider>
            <div id="list-container">
                <div id="list-section">
                    <div className="rank-stage">
                        <div className="title-wrap">
                            <div className="inBox">
                                <h2>별별랭킹</h2>
                                <a href="https://afevent2.afreecatv.com/app/rank/index.php" target="_blank">BJ 랭킹</a>
                            </div>
                        </div>
                        <div className="bg-box" id="bgBox"></div>
                        <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        className="list-carousel"
                        wrapperTag="ul"
                        centeredSlides= {true}
                        slidesPerView= "auto"
                        loop= {true}
                        loopedSlides= {larvaBestDataCnt}
                        data-broadcast="live"
                        autoplay= {{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        navigation= {{
                            prevEl: '.list-carousel .btn-prev',
                            nextEl: '.list-carousel .btn-next',
                        }}
                        pagination= {{
                            el: ".list-carousel .pagination",
                            clickable: true,
                        }}
                        onSlideChangeTransitionStart={()=>{
                            const imgFileUrl = ( document.querySelector('.swiper-slide-active .thumbs-box a img') as HTMLImageElement ).src;
                            const bgBox = document.getElementById('bgBox') as HTMLDivElement;
                            bgBox.style.backgroundImage=`url(${imgFileUrl})`;
                        }
                        }>
                            {larvaBestData.map((item: ILarvaRankData) => {
                                return(
                                <SwiperSlide tag="li" data-type="cBox" key={item.broad_no}>
                                <div className="thumbs-box">
                                    <Anchor href={item.url} target="_blank" rel='noopener noreferrer' className={thumbClass}>
                                        <img src={`${item.sn_url}.webp`} onError={handleLiveImageError(item.sn_url)} alt=""/>
                                    </Anchor>
                                    {item.visit_broad_type === 1 ? <span className="allow">탐방허용</span> : <></>}
                                    <span className="time">{item.broad_start.substring(5)} 방송시작</span>
                                    <Button className="btn-later" tip="나중에보기" onClick={()=>onClickLaterViewBtn(item.broad_no)}><span>나중에보기</span></Button>
                                </div>
                                <div className="info-box">
                                    <h3><Anchor href={item.url} className="title" target="_blank">{item.broad_title}</Anchor></h3>
                                    <DetailMenu rankItem={item}/>
                                </div>
                                </SwiperSlide>
                            )
                            })}
                            <div className='pagination'></div>
                            <Button
                                className="btn-prev"
                                tabIndex={0}
                                role="button"
                                ariaLabel="Previous slide">
                                이전
                            </Button>
                            <Button
                                className="btn-next"
                                tabIndex={0}
                                role="button"
                                ariaLabel="Next slide">
                                다음
                            </Button>
                        </Swiper>
                    </div>
                    <div className="cBox-list_wrap">
                        {larvaListData.map((broadItem: ILarvaRankListData) => {
                            return(
                                <BroadItem broadItemList={broadItem} autoTagStyle={autoHashTagStyle} />
                            );
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
        
        <ShareContextMenu />
        <ThreeDotContextMenu/>
        <ReactToast />
      </div>
    );
   }else{
    return(<></>);
   }
    
}

export default AfPcStarRankPage;
