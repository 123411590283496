import React, { useState } from 'react'

export const IctContext = React.createContext<IIctContext | null>(null);

export interface Ict {
	bjId: string
}

export interface IIctContext{
    bjId : string
    setBjId : React.Dispatch<React.SetStateAction<string>>
    bjNick : string
    setBjNick : React.Dispatch<React.SetStateAction<string>>
    rankCode : string
    setRankCode : React.Dispatch<React.SetStateAction<string>>
}

const IctProvider: React.FC = ({ children }) =>{

    const [bjId, setBjId] = useState("");
    const [bjNick, setBjNick] = useState("");
    const [rankCode, setRankCode] = useState("");
    

    return (
        <IctContext.Provider value={{bjId, bjNick, rankCode, setBjId, setBjNick,setRankCode}} >
            {children}
        </IctContext.Provider>
    )
}
export default IctProvider;