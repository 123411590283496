import React, { useCallback, useState } from 'react';
import Anchor from 'component/common/Anchor';
import ContextMenu from "component/ict/ContextMenu";
import { ILarvaRankData } from 'types/larva';
import { profileImage } from 'lib/utils';

interface IDetailMenu {
	rankItem :ILarvaRankData
}


const DetailMenu : React.FC<IDetailMenu> = ({rankItem}) => {
	const [openIct, setOpenIct] = useState(false);
	
	const onchangeEvent = (e: React.MouseEvent<HTMLAnchorElement>)=>{
		//a Tag 클릭막기
		e.preventDefault();

		setOpenIct(true);
		return false;
	}

	const handleOpenIct = (flag: boolean) => {
		setOpenIct(flag);
	}
    /**
     * 프로필 이미지 에러 
     * @param e 
     */
	const handleImageError = useCallback((userId) => (e: React.ChangeEvent<HTMLImageElement>) => {
		let profileImg = profileImage(userId, true);
		e.target.src = profileImg;
        /**
         * jpg도 에러날시 기본 이미지로
         */

        e.target.onerror = () => {
			profileImg = `${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`
			e.target.src = profileImg;
			return false;
		}

		return false;
	}, []);
    
    return (
		<div className='details'>
			<Anchor href={`${BJ_AFREECATV}/${rankItem.user_id}`} className="thumb" target="_blank">
				<img 
					src ={profileImage(rankItem.user_id)}  
					onError={handleImageError(rankItem.user_id)} 
					loading="lazy"
					alt=""/>
			</Anchor>
			<Anchor className="nick" target="_blank" onClick={onchangeEvent}><span>{rankItem.user_nick}</span></Anchor>
			<span className='views'><em>{rankItem.total_view_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}명 참여중</em></span>
			{ openIct ? <ContextMenu bjId={rankItem.user_id} bjNick={rankItem.user_nick} openIct={openIct} handleOpenIct={handleOpenIct}></ContextMenu> : ""}
		</div>
	);
}

export default DetailMenu;
