import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getLoginUserInfo } from 'lib/utils';
import { getCookie } from 'lib/cookie';
import { store } from 'modules/store';
import { setTheme } from 'modules/config';
import "./i18n";
import App from './App';
import UserAgentInfo from 'user-agent-info';

declare global {
	interface Window {
		userAgentInfo: any;
	}
}

if (process.env.NODE_ENV === "production") {
  console.log = function no_console() {};
  console.warn = function no_console() {};
}

window.userAgentInfo = new UserAgentInfo();
window.userAgentInfo
.setUserAgentInfo()
.then(() => {
  // console.table(window.userAgentInfo);
})
.catch(() => {})
.finally(() => {
  reactRender();
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

getLoginUserInfo();
/**
 * 테마 초기화
 */
 const initTheme = () => {
	const theme = getCookie('theme');
	if (!theme) {
    return;
  }
  if (theme === 'dark') {
    store.dispatch(setTheme('dark'));
    const html = document.getElementsByTagName('html')[0];
    if (html) {
      html.setAttribute('dark', 'true');
    }
    document.body.classList.add('thema_dark');
  }
};
initTheme();
// const root = ReactDOM.createRoot(rootElement);
const reactRender = () =>{
  ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
        <Provider store={store}>
              <App />
        </Provider>
        </BrowserRouter>
    </React.StrictMode>
    ,
		document.getElementById('root')
  );  
}