import React, { useEffect, useState, useCallback } from 'react';
import useScript from 'lib/hooks/useScript';
import { getLarvaList } from 'lib/api/larva';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper,SwiperSlide } from 'swiper/react/swiper-react.js';
import Anchor from 'component/common/Anchor';
import Button from 'component/common/Button';
import { ILarvaRankListData, ILarvaSendData, ILarvaRankData } from 'types/larva';
import MobileDetailMenu from 'component/ict/MobileDetailMenu';
import MobileBroadItem from 'component/af/mobile/MobileBroadItem';
import { loadStyleSheet } from 'lib/loadStyleSheets';
import { profileImage } from 'lib/utils';

const AfPcStarRankPage: React.FC = () => {

    const [larvaBestData, setLarvaBestData] = useState<ILarvaRankData[]>([]);
    const [larvaBestDataCnt, setLarvaBestDataCnt] = useState(0);
    const [larvaListData, setLarvaListData] = useState<ILarvaRankListData[]>([]);
    const [isAfreecaWebView, setIsAfreecaWebView] = useState(false);
    const [thumbClass, setThumbClass] = useState("");

    const oLarvaSendData:ILarvaSendData = {
        szWork : "getStarRank",
        nCount : 4
    };

    const linkStatus = useScript(`${STATIC_M_AFREECATV}/js/app/lib/afreeca.link.js`);

    const onClickStationLink = (e: React.MouseEvent<HTMLAnchorElement>, user_id:string) =>{
        e.preventDefault();
        afreeca.link.goStation(user_id);
    }

    const onClickLiveBroad = (user_id:string, broad_no:number)=>{
        afreeca.link.live(user_id,broad_no);
    }

    useEffect(()=>{
        //아프리카 앱뷰면 헤더 제거
        if(linkStatus==='ready') setIsAfreecaWebView(afreeca.link.isWebview());
    })
    
    useEffect(() => {
        const oLarvaData = getLarvaList(oLarvaSendData);
        
        oLarvaData.then((result) => {
            setLarvaBestData(result.DATA.bestData);
            setLarvaListData(result.DATA.themeData);
            setLarvaBestDataCnt(result.DATA.bestDataCnt);
        });

        loadStyleSheet({
			url: `${RES_AFREECATV}/images/mobile/afreeca_mobile.png`,
            rel: 'apple-touch-icon'
		});
    },[]);

    const onBackClick = () =>{
        window.history.back();
    }
    /**
     * 프로필 이미지 에러 
     * @param e 
     */
	const handleImageError = useCallback((userId) => (e: React.ChangeEvent<HTMLImageElement>) => {
		let profileImg = profileImage(userId, true);
		e.target.src = profileImg;
        /**
         * jpg도 에러날시 기본 이미지로
         */

        e.target.onerror = () => {
			profileImg = `${RES_AFREECATV}/images/afmain/img_thumb_profile.gif`
			e.target.src = profileImg;
			return false;
		}

		return false;
    }, []);
    
    /**
     * 라이브 이미지 에러
     * @param e
     */
    const handleLiveImageError = useCallback((liveThumb) => (e: React.ChangeEvent<HTMLImageElement>) => {
        let liveImage = `${liveThumb}.jpg`;

        let image = new Image();

        image.src = liveImage;

        image.onload = () => {
            e.target.src = liveImage;
        }

        image.onerror = () => {
            setThumbClass("thumb-default");
        }

        return false;
    }, []);
    

    if(larvaBestDataCnt>0){
        return (
        <div id="MobileWeb" className="broadRanking_wrap">
            {!isAfreecaWebView ? 
            <header className="_header">
                <div className="_header_innr">
                    <h2>별별랭킹</h2>
                    <Button className="back" onClick={onBackClick}>뒤로가기</Button>
                </div>
            </header> : <></>}
            <main role="main" id="contents">
                <div className="rank-stage">
                    <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    className="list-carousel"
                    wrapperTag="ul"
                    centeredSlides= {true}
                    slidesPerView= "auto"
                    loop= {true}
                    loopedSlides= {larvaBestDataCnt}
                    autoplay= {{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination= {{
                        el: ".list-carousel .pagination",
                        clickable: true,
                    }}
                    >
                        {larvaBestData.map((item: ILarvaRankData, index: number) => {
                            return(
                            <SwiperSlide tag="li" data-type="cBox" role="group" >
                            <Anchor 
                                onClick={()=>onClickLiveBroad(item.user_id,item.broad_no)} className="thumbs-box">
                                    <img 
                                        src={`${item.sn_url}.webp`} 
                                        onError={handleLiveImageError(item.sn_url)}
                                        alt=""/>
                            </Anchor>
                            <div className="info">
                                <Anchor href={`${BJ_AFREECATV}/${item.user_id}`} onClick={(e)=>onClickStationLink(e,item.user_id)} target="_blank" className="thumb">
                                    <img 
                                        src ={profileImage(item.user_id)}  
                                        onError={handleImageError(item.user_id)} 
                                        loading="lazy"
                                        alt=""/>
                                </Anchor>
                                <h3><Anchor onClick={()=>onClickLiveBroad(item.user_id,item.broad_no)} className="title">{item.broad_title}</Anchor></h3>
                                <MobileDetailMenu rankItem={item}/>
                            </div>
                            </SwiperSlide>
                        )
                        })}
                        <div className='pagination'></div>
                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                    </Swiper>
                </div>
                {larvaListData.map((broadItem: ILarvaRankListData, index: number) => {
                    return(
                    <MobileBroadItem broadItemList={broadItem} rankingCode ={broadItem.rank_code}/>
                    );
                })}
                </main>
            </div>
        );
    }else{
        return(<></>);
    }
}

export default AfPcStarRankPage;
