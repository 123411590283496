import apiClient, { postParams } from 'lib/api/apiClient';
import { ILarvaSendData } from 'types/larva'

// 별별랭킹 데이터 가져오기
export const getLarvaList = async (oData: ILarvaSendData) => {
	const { data } = await apiClient.post(`${LIVE_AFREECATV}/api/star_rank.php`, postParams(oData));

	return data;
};

// 이벤트 태그 css 가져오기
export const getEventTagStyle = () => {
	return fetch(`${RES_AFREECATV}/event_auto_hashtags.php`, {cache: "no-cache"}).
		then(response => response.json());
};