import React, { useState } from 'react';
import Anchor from 'component/common/Anchor';
import ContextMenu from "component/ict/ContextMenu";
import { ILarvaRankData } from 'types/larva';

interface IBroadDetailMenu {
	rankItem :ILarvaRankData
}


const BroadDetailMenu : React.FC<IBroadDetailMenu> = ({rankItem}) => {
	const [openIct, setOpenIct] = useState(false);
	

	const onchangeEvent = (e: React.MouseEvent<HTMLAnchorElement>)=>{
		//a Tag 클릭막기
		e.preventDefault();
		
		setOpenIct(true);
		return false;
	}

	const handleOpenIct = (flag: boolean) => {
		setOpenIct(flag);
	}
    
    return (
		<div className='details'>
            <Anchor className="nick" onClick={onchangeEvent}><span>{rankItem.user_nick}</span></Anchor>
            <span className='views'><em>{rankItem.total_view_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</em></span>
			{ openIct ? <ContextMenu bjId={rankItem.user_id} bjNick={rankItem.user_nick} openIct={openIct} handleOpenIct={handleOpenIct}></ContextMenu> : ""}
		</div>
	);
}

export default BroadDetailMenu;
