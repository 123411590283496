import React from 'react';

import { ILarvaRankListData, ILarvaRankData } from 'types/larva';
import BroadItemBox from 'component/af/pc/BroadItemBox';

interface BroadItem {
	broadItemList :ILarvaRankListData
    autoTagStyle: any
}

const BroadItem : React.FC<BroadItem> = ({broadItemList, autoTagStyle}) => {
    if (broadItemList.rank_list.length > 0) {

        return(
            <div className='cBox-list' data-broadcast="live">
                <div className='rank-title'>
                    <h3>{broadItemList.title}</h3>
                    <span>{broadItemList.detail}</span>
                </div>
                <ul>
                    {broadItemList.rank_list.map((broadItem: ILarvaRankData, index: number) => {
                        return(<BroadItemBox broadItem={broadItem} itemIndex ={index+1} autoTagStyle={autoTagStyle}/>);
                    })}
                </ul>
            </div>
            );
    }else{
        return(<></>);
    }
    
}

export default BroadItem;
