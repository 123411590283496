import apiClient, { postParams } from 'lib/api/apiClient';
import { IGetStationInfoParams, ISetLoginActionParams } from 'types/auth';

/**
 * 로그인 액션
 */
export const setLoginAction = async ({
	uid: szUid,
	pw: szPassword,
	isSaveId,
	isSavePw,
	isSaveJoin,
	isRetainLogin,
	isSecondLogin,
}: ISetLoginActionParams) => {
	const params = {
		szWork: isSecondLogin ? 'second_login' : 'login',
		szType: 'json',
		szUid,
		szPassword,
		isSaveId,
		isSavePw,
		isSaveJoin,
		isLoginRetain: isRetainLogin === true ? 'Y' : 'N',
	};

	const { data } = await apiClient.post(`${LOGIN_AFREECATV}/app/LoginAction.php`, postParams(params), {
		withCredentials: true,
	});

	return data;
};

/**
 * 로그인 정보 가져옴
 */
export const getStationInfo = ({ bjId }: IGetStationInfoParams) =>
	apiClient.get(`${AFEVENT2_AFREECATV}/api/get_private_info.php?szBjId=${bjId}`);

/**
 * 로그아웃
 */
export const setLogOutAction = async () => {
	const { data } = await apiClient.get(`${LOGIN_AFREECATV}/app/LogOut.php?szType=json`);

	return data;
};
