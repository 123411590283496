export const DOMAIN = 'afreecatv.com';
// constant.js 미정의된 도메인
export const DEAPIDEV_AFREECATV = '//deapidev.afreecatv.com';
export const DEAPI_AFREECATV = '//deapi.afreecatv.com';
export const TKAPI_AFREECATV = '//tkapi.afreecatv.com';
export const MYAPI_AFREECATV = '//myapi.afreecatv.com';

// 로그 콜렉터
export const HTTP_COLECTOR_ENV_URL_DEV = '//tcollector1.afreecatv.com:8080/CONFIG/';
export const HTTP_COLECTOR_LOG_URL_DEV = '//sdev1.afreecatv.com:8080/gather?sv=AF';
export const HTTP_COLECTOR_ENV_URL = '//collector1.afreecatv.com/CONFIG/';
export const HTTP_COLECTOR_LOG_URL = '//collector1.afreecatv.com/gather';

export const STIMG_URL = '//stimg.afreecatv.com';
export const PROFILE_IMG_URL = '//profile.img.afreecatv.com';