import { getCookie } from 'lib/cookie';
import { STIMG_URL } from 'lib/constants';

/**
 * OS 가져옴
 */
export const getOs = () => {
	const { CONSTANTS, platform } = window.userAgentInfo;

	switch (platform) {
		case CONSTANTS.PLATFORM.IOS:
			return 'ios';
		case CONSTANTS.PLATFORM.ANDROID:
			return 'android';
		case CONSTANTS.PLATFORM.MAC:
			return 'mac';
		case CONSTANTS.PLATFORM.WINDOWS:
			return 'windows';
		default:
			return 'etc';
	}
};

export const getLoginUserInfo = () => {
	const ticket = decodeURIComponent(getCookie('PdboxUser')) || '';
	let id = '',
		nickname = '',
		isRealName = false,
		os = '',
		age = 0,
		sex = '',
		uno = 0,
		isAdultCheck = false;

	if (ticket === '') {
		return {
			id,
			nickname,
			isRealName,
			os,
			age,
			sex,
			uno,
			isAdultCheck,
		};
	}

	for (const item of ticket.split('&')) {
		if (/^(uid=)/.test(item)) {
			// 로그인 ID
			id = item.replace(/^(uid=)/, '');
		}
		if (/^(unick=)/.test(item)) {
			// 로그인 닉네임
			nickname = item.replace(/^(unick=)/, '');
		}
		if (/^(chnnl_name_chk=)/.test(item)) {
			// 실명인증 여부 체크
			isRealName = Number(item.replace(/^(chnnl_name_chk=)/, '')) === 1 ? true : false;
		}
		if (/^(age=)/.test(item)) {
			age = Number(item.replace(/^(age=)/, ''));
		}
		if (/^(sex=)/.test(item)) {
			sex = item.replace(/^(sex=)/, '');
		}
		if (/^(uno=)/.test(item)) {
			uno = Number(item.replace(/^(uno=)/, ''));
		}
		if (/^(sess_adult_chk=)/.test(item)) {
			// 성인인증 여부 체크
			isAdultCheck = Number(item.replace(/^(sess_adult_chk=)/, '')) === 1 ? true : false;
		}
	}
	os = getOs(); // 접속 OS

	return {
		id,
		nickname,
		isRealName,
		os,
		age,
		sex,
		uno,
		isAdultCheck,
	};
};

/**
 * 프로필 이미지
 * @param userId 
 */
export const profileImage = (userId: string, errorCheck: boolean = false) => {
	if(errorCheck) {
		return `${STIMG_URL}/LOGO/${userId.substring(0, 2)}/${userId}/${userId}.jpg`;
	}

    return `${STIMG_URL}/LOGO/${userId.substring(0, 2)}/${userId}/m/${userId}.webp`;
}

