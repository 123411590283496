import React, { useEffect, useState, useRef } from 'react';
import { checkFavorite, setFavorite } from 'lib/api/favorite';
import { useAuth } from "react-navigationbar";
import Button from 'component/common/Button';
import { useOnClickOutside } from 'usehooks-ts'

interface IMobileContextMenu {
	bjId : string
    openIct : boolean
    handleOpenIct : (params:boolean) => void
}



const MobileContextMenu : React.FC<IMobileContextMenu>  = ({bjId,openIct,handleOpenIct}) => {
    const { userId } = useAuth();
    const [isFavorite, setIsFavorite] = useState(false);
    
    const iconRef = useRef(null);
    
    const handleClickOutside = (e : MouseEvent) => {
        e.stopPropagation();
        handleOpenIct(false);
    }

    const onClickfavoriteBtn = () => {
        if (userId === '') {
            alert('로그인후 시도하세요');
		} else {
			// 로그인
			const action = isFavorite ? 'delete' : 'add';
			const response = setFavorite(bjId, action);
			response
				.then(({ result, message }) => {
					if (result === 1) {
                        alert(message);
                        isFavorite ? setIsFavorite(false) : setIsFavorite(true);						
					}
				})
				.catch(() => {
				});
		}
    }
    const onClickStationBtn = () =>{
        afreeca.link.goStation(bjId);
    }

    useOnClickOutside(iconRef,handleClickOutside);

    useEffect(() => {
        if(openIct){
            const response = checkFavorite(bjId);
            response
                .then(({ result, data }) => {
                    if (result !== 1) {
                        return;
                    }

                    if (data) {
                        setIsFavorite(true);
                    }else{
                        setIsFavorite(false);
                    }
                })
                .catch(() => {});
        }
        
    },[bjId]);

    
    


    return(
    <div className="icon-menu" ref= {iconRef}>
        <Button className="bj_bs" tip="방송국"onClick={onClickStationBtn}><span>방송국 가기</span></Button>
        <Button className={isFavorite ? "favor on" : "favor" } tip={isFavorite ? "즐겨찾기 제거" : "즐겨찾기 추가"} onClick={onClickfavoriteBtn}><span>즐겨찾기</span></Button>
    </div>
);
}

export default MobileContextMenu;
