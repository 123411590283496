// Load a css from given `url`
export const loadStyleSheet = function (obj) {
	return new Promise(function (resolve, reject) {
		const existingLink = document.getElementById(obj.id);

		if (existingLink) {
			resolve(true);
		} else {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.rel = obj.rel;
			link.href = obj.url;
			link.type = 'text/css';

			link.addEventListener('load', function () {
				// The link is loaded completely
				resolve(true);
			});
			link.addEventListener('error', function () {
				// 하나라도 에러면 reject로 해당 URL 반환
				reject(this.href);
			});

			document.head.appendChild(link);
		}
	});
};
