import apiClient, { postParams } from 'lib/api/apiClient';

// 즐겨찾기 체크
export const checkFavorite = async (id: string) => {

	const params = {
		id
	};

	const { data } = await apiClient.post(`${API_M_AFREECATV}/station/favorite/a/check`, postParams(params));

	return data;
};

// 즐겨찾기 추가, 삭제
export const setFavorite = async (id: string, action: 'add' | 'delete') => {
	const params = {
		id,
	};
	const { data } = await apiClient.post(`${API_M_AFREECATV}/station/favorite/a/${action}`, postParams(params));

	return data;
};
