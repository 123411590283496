import React, { useEffect, useState, useRef, useContext } from 'react';
import { checkFavorite, setFavorite } from 'lib/api/favorite';
import { useAuth } from "react-navigationbar";
import Button from 'component/common/Button';
import { useOnClickOutside } from 'usehooks-ts'
import { toast } from 'afreecatv-react-toast';
import { ThreeDotContext } from 'component/context/ThreeDotProvider';
import { ShareContext } from 'component/context/ShareProvider';
import { useTranslation } from 'react-i18next';
import { setLaterView } from 'lib/api/laterView';


const ThreeDotContextMenu : React.FC  = () => {
  
		const {dotBroad, dotLoad, dotTop, dotLeft, setDotLoad} = useContext(ThreeDotContext);
    const {setShareLoad, setShareBroad, setShareTop, setShareLeft} = useContext(ShareContext);
	
    const moreDotRef = useRef<HTMLDivElement>(null);
		const {t} = useTranslation();
    
    const handleClickOutside = (e : MouseEvent) => {
			e.stopPropagation();
			// setShareLoad(false);
			setDotLoad(false);
    }

    

    useOnClickOutside(moreDotRef,handleClickOutside);

		const onClickShare = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
			setShareLoad(true);
			setShareBroad(dotBroad);
			setShareTop(dotTop);
			setShareLeft(dotLeft);
			setDotLoad(false);
		}
    
		const onClickLaterViewBtn = (broad_no : number) =>{

			const response = setLaterView('add', broad_no);
					
			response
				.then(({ result, message, data }) => {
					switch (result) {
						case 1:
													toast.success(message);
							return;
						default:
							if (data.message) {
								toast.success(data.message);
							}
							return;
					}
				})
				.catch(() => {
					toast.success('서비스를 이용하실 수 없습니다. 잠시 후 다시 이용해 주십시오.');
				});
		}

    


    return(
			<div ref={moreDotRef} className="more-layer" style= {{
				visibility : dotLoad ? "visible":"hidden", 
				top: dotTop, 
				left : dotLeft-(moreDotRef.current !== null ? moreDotRef.current.getBoundingClientRect().width : 0) 
			}}>
				<Button id="laterview" type="button" onClick = {(e)=>{onClickLaterViewBtn(dotBroad.broad_no)}}><span>{t("나중에 보기")}</span></Button> 
				<Button id="share" type="button" onClick ={(e)=>{onClickShare(e)}}><span>{t("공유하기")}</span></Button> 
		</div>
	);
}

export default ThreeDotContextMenu;
