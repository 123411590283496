import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import { getCookie } from './lib/cookie';
// import tran_en_US from 'locales/en_US.json';
// import tran_ja_JP from 'locales/ja_JP.json';
// import tran_th_TH from 'locales/th_TH.json';
// import tran_vi_VN from 'locales/vi_VN.json';
// import tran_zh_CN from 'locales/zh_CN.json';
// import tran_zh_TW from 'locales/zh_TW.json';

// const resources = {
//   en_US: { translation: tran_en_US },
//   ja_JP: { translation: tran_ja_JP },
//   th_TH: { translation: tran_th_TH },
//   vi_VN: { translation: tran_vi_VN },
//   zh_CN: { translation: tran_zh_CN },
//   zh_TW: { translation: tran_zh_TW },
// };

// i18n.use(initReactI18next).init({
//   resources,
//   lng: 'ko_KR',
//   fallbackLng: 'ko_KR',
//   debug: true,
//   keySeparator: false,
//   interpolation: {
//     escapeValue: false,
//   },
// });

/**
 * 쿠키및 브라우져 셋팅 언어셋 리스트
 */
const detectLanguages = () => {
	let langs = [getCookie('_lang'), navigator.languages && navigator.languages[0], navigator.language];
	if (navigator.languages) {
		langs = langs.concat(navigator.languages.slice(1));
	}
	langs = langs.filter((val) => val); //없는 값 제거하는 필터링

	return langs;
};

/**
 * 언어코드 => 언어코드_국가 변환
 * @param lang 언어코드
 * @returns 언어 코드 변환 값
 */
const transCountryLang = (lang: string) => {
	switch (lang) {
		case 'en':
			return 'en_US';
		case 'ja':
			return 'ja_JP';
		case 'th':
			return 'th_TH';
		case 'vi':
			return 'vi_VN';
		case 'zh':
			return 'zh_CN';
		case 'zh_TW':
			return 'zh_TW';
		default:
			return 'ko_KR';
	}
};

/**
 * 지원가능한 언어셋
 */
const allowLanguage = () => {
	const possibleLanguages = ['ko', 'en', 'ja', 'th', 'zh', 'zh_TW', 'vi'];
	let langs = detectLanguages();

	for (let i = 0; i < langs.length; i++) {
		let lang = langs[i];
		lang = lang.replace('-', '_');

		if (['zh_hk', 'zh_mo', 'zh_hant'].indexOf(lang.toLowerCase()) > -1) {
			lang = 'zh_TW';
		}

		if (possibleLanguages.indexOf(lang) >= 0) {
			return transCountryLang(lang);
		}

		if (lang.indexOf('_') >= 0) {
			lang = lang.split('_')[0];
			if (possibleLanguages.indexOf(lang) >= 0) {
				return transCountryLang(lang);
			}
		}
	}
	return 'ko'; //기본값
};

const lang = allowLanguage();
const langCode = lang.split('_')[0] || 'ko'; // 언어코드로 변경
document.documentElement.lang = langCode;

i18n.use(backend)
	.use(initReactI18next)
	.init({
		lng: lang,
		fallbackLng: 'ko_KR',
		debug: false,
		keySeparator: false,
		nsSeparator: false,
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
		backend: {
			loadPath: 'locales/{{lng}}.json',
			parse: (data: any) => {
				return data;
			},
			ajax: (url: any, options: any, callback: any, data: any) => {
				import(`./${url}`) // url = loadPath
					.then((res: any) => {
						callback(res, { status: '200' });
					})
					.catch((error) => {
						// callback(error, { status: '200' });
					});
			},
		},
	});

export default i18n;
