import React, { useState } from 'react'
import { ILarvaRankData } from 'types/larva';

export const ThreeDotContext = React.createContext<IThreeDotContext>({} as IThreeDotContext);


export interface IThreeDotContext{
    dotBroad: ILarvaRankData
    dotLoad : boolean
    dotTop : number 
    dotLeft : number
    setDotLoad : React.Dispatch<React.SetStateAction<boolean>> 
    setDotTop : React.Dispatch<React.SetStateAction<number>>
    setDotLeft : React.Dispatch<React.SetStateAction<number>>
    setDotBroad : React.Dispatch<React.SetStateAction<ILarvaRankData>>
}

const ThreeDotProvider: React.FC = ({ children }) =>{
    const [dotBroad, setDotBroad] = useState({} as ILarvaRankData)
    const [dotLoad, setDotLoad] = useState(false);
    const [dotTop, setDotTop] = useState(0);
    const [dotLeft, setDotLeft] = useState(0);

    return (
        <ThreeDotContext.Provider value={{dotBroad, dotLoad, dotTop, dotLeft, setDotBroad, setDotLoad, setDotTop, setDotLeft}} >
            {children}
        </ThreeDotContext.Provider>
    )
}
export default ThreeDotProvider;