// https://www.npmjs.com/package/react-cookie
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name: string, val: string | number, option?: any) => {
	return cookies.set(name, val, { ...option });
};

export const getCookie = (name: string) => {
	const cookie = cookies.get(name);

	return typeof cookie === 'undefined' || cookie === null ? '' : cookie;
};

export const removeCookie = (name: string, option?: any) => {
	return cookies.remove(name);
};
