import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStationInfo, setLogOutAction } from 'lib/api/auth';
import { RootState } from 'modules/store';
import { IGetStationInfoParams } from 'types/auth';
import { IConfigState, TThemeType, TListPath3 } from 'types/config';

export const initNextVodInfo = {
	titleNo: 0,
	title: '',
	url: '',
	thumb: '',
	duration: '',
	userId: '',
	userNick: '',
	listPath3: 'recommend' as TListPath3,
	dataSrcType: '',
	listViewType: '',
	listDataType: '',
	dataType: '',
	listDataDetail: [''],
	recDetail: '',
};

const initialState: IConfigState = {
	playerType: '',
	lang: 'ko_KR',
	theme: 'light',
	log: {
		isQuickViewPlus: false,
		isClickAdBalloonIcon: false,
		vin: {
			path_key: '',
			path1: '',
			path2: '',
		},
		refer: '',
	},
	user: {
		loginId: '',
		loginNickName: '',
		isRealNameCheck: false,
		isAdultCheck: false,
		os: '',
		nationalityCd: -1,
		userNo: 0,
		loginAge: 0,
		loginSex: '',
		loginUno: 0,
		stationNo: 0,
		loginEmail: '',
		newNote: 0,
		adPoint: 0,
		adFrame: 0,
	},
	iframeApiModeOption: {
		cmd: '',
		mutePlay: false,
		showChat: false,
		autoPlay: false,
		isAdShow: false,
	}
};


export const setLogOutActionAsync = createAsyncThunk('config/setLogOutAction', async () => {
	const response = await setLogOutAction();

	return response.data;
});

const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setTheme(state, action: PayloadAction<TThemeType>) {
			state.theme = action.payload;
		},
        setUserInfo(
			state,
			{
				payload: { id, nickname, isRealName, os, age, sex, uno, isAdultCheck },
			}: PayloadAction<{
				id: string;
				nickname: string;
				isRealName: boolean;
				isAdultCheck: boolean;
				os: string;
				age: number;
				sex: string;
				uno: number;
			}>
		) {
			state.user.loginId = id;
			state.user.loginNickName = nickname;
			state.user.isRealNameCheck = isRealName;
			state.user.isAdultCheck = isAdultCheck;
			state.user.os = os;
			state.user.loginAge = age;
			state.user.loginSex = sex;
			state.user.loginUno = uno;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getStationInfoAsync.fulfilled, (state, action) => {
			const {
				AD_FRAME: adFrame,
				AD_POINT: adPoint,
				LOGIN_EMAIL: loginEmail,
				LOGIN_ID: loginId,
				LOGIN_NICK: loginNick,
				NOTE_NEW: newNote,
				STATION_NO: stationNo,
			} = action.payload.CHANNEL;

			state.user.adFrame = Number(adFrame);
			state.user.adPoint = Number(adPoint);
			state.user.loginEmail = loginEmail;
			state.user.loginId = loginId;
			state.user.loginNickName = loginNick;
			state.user.newNote = Number(newNote);
			state.user.stationNo = Number(stationNo);
		});
		builder.addCase(setLogOutActionAsync.fulfilled, (state) => {
			Object.assign(state.user, initialState.user);
			state.log.isQuickViewPlus = false; // 로그아웃 시 퀵뷰플러스 체크 false
		});
	},
});

export const {
	setTheme,
    setUserInfo
} = configSlice.actions;

export const getStationInfoAsync = createAsyncThunk('config/getStationInfo', async (params: IGetStationInfoParams) => {
	const response = await getStationInfo(params);

	return response.data;
});

export const selectTheme = (state: RootState) => state.config.theme;
export const selectUserInfo = (state: RootState) => state.config.user;

export default configSlice.reducer;
